import settings from '@settings';

export const imageCardContainer = {
	// backgroundColor: settings.colors.white,
	borderBottom: `1px solid ${settings.colors.dark}`,
	padding: settings.padding.small
};

export const imageCardFieldContainer = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: settings.sizes.small
};

export const labelStyle = {
	fontWeight: 600,
	textTransform: 'uppercase'
};