import { graphql, useStaticQuery } from 'gatsby';

const useGetFilters = () => {
	const filters = useStaticQuery(graphql`
        query FiltersQuery {
            allGraphCmsFilter {
                edges {
                    node {
                        id
                        remoteId
                        label
                        categories
                    }
                }
            }
        }
    `);

	return [
		...filters.allGraphCmsFilter.edges.map(event => event.node)
	];
};

export default useGetFilters;