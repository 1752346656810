import React from 'react';

import Button from '@components/buttons/Button';
import FlexBox from '@components/layout/FlexBox';
import useGetColors from '@hooks/useGetSiteColors';
import settings from '@settings';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

const CalendarElement = ({event}) => {
	const {secondary} = useGetColors();

	const dateHandler = dates => {
		if (dates.length === 0) {
			return null;
		}
		if (dates.length === 1) {
			return settings.functions.transformDate(dates[0]) ;
		}
		if (dates.length > 1) {
			return `${settings.functions.transformDate(dates[0])} - ${settings.functions.transformDate(dates[dates.length - 1])}`;
		}
		return null;
	};

	return (
		<FlexBox
			direction="row"
			align="center"
			justify="space-between"
			hasFullWidth
			changeToColumn
			gap={settings.sizes.small}
			styles={{padding: settings.padding.medium, 'p': {marginBottom: 0}}}
		>
			<p css={{ fontWeight:'600 !important', width: '300px'}}>
				{event.title}
			</p> 
			<p css={{ fontSize: '.9rem !important', width: '300px'}}>
				{dateHandler(event.date)}
			</p>
			<p css={{ fontSize: '.9rem !important', width: '300px'}}>
				{event.eventLocation.name}
			</p>
			
			<Button
				color={settings.colors.dark}
				hoverColor={secondary}
				text='Go To Event >'
				size='small'
				onClick={() => navigate(`/app/events/${event.remoteId}`)}
			/>
		</FlexBox>
	);
};

CalendarElement.propTypes = {
	event: PropTypes.object.isRequired
};

export default CalendarElement;