import { graphql, useStaticQuery } from 'gatsby';

const useGetSeasons = () => {
	const seasons = useStaticQuery(graphql`
        query getAllSeasons {
            allGraphCmsSeason(sort: {order: DESC, fields: order}) {
                edges {
                    node {
                        id
                        remoteId
                        title
                        calendars {
                            remoteId
                            title
                            id
                            order
                            events {
                                date
                                title
                                id
                                remoteId
                                eventLocation {
                                    adress
                                    name
                                    nation {
                                        name
                                        id
                                        short
                                        flag {
                                            id
                                            url
                                            gatsbyImageData(
                                                height: 12
                                            )
                                        }
                                    }
                                    id
                                    remoteId
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

	return ({seasons: seasons.allGraphCmsSeason.edges.map(edge => edge.node)});
};

export default useGetSeasons;