import React, { useState , useMemo , useEffect , useCallback } from 'react';

import Button from '@components/buttons/Button';
import FullButton from '@components/buttons/FullButton';
import FlexBox from '@components/layout/FlexBox';
import Divider from '@components/surfaces/Divider';
import GET_EVENT_QUERY from '@content/Backend/api/get_event_query';
import useMutateEvents from '@hooks/useMutateEvents';
import loadable from '@loadable/component';
import settings from '@settings';
import {navigate} from 'gatsby';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import client from '../../../../apollo/client';
import algoliaIndex from '../../../../axios/algoliaClient';
import DisplayImages from './content/DisplayImages';

const Uploader = loadable(() => import('../Uploader'));

const EventPage = ({title, id}) => {
	const [event, setEvent] = useState();

	const [selectedDay, setSelectedDay] = useState(() => {
		if (event?.data) {
			return ({
				value: event?.date[0],
				label: settings.functions.transformDate(event?.date[0])
			});
		}
		return null;
	});

	const {mutateEvent, publishEvent} = useMutateEvents();
	const [eventData, setEventData] = useState();
	const [dataHasChangend, setDataHasChanged] = useState(false);

	const getEvent = useCallback( async () => {
		const {data} = await client.query({
			query: GET_EVENT_QUERY,
			variables: {
				id
			}, 
			fetchPolicy: 'no-cache'
		});
		setEvent(data.event);

		if (data.event?.date?.length > 0 && !selectedDay) {
			setSelectedDay({
				value: data.event.date[0],
				label: settings.functions.transformDate(data.event.date[0])
			});
		}
		setEventData(data?.event?.event_json);
		setDataHasChanged(false);
	}, [id, selectedDay]);

	const datesToSelect = useMemo(() => {
		const datesArray = [];
		if (event) {

			event?.date?.forEach(date => {
				datesArray.push({
					value: date,
					label: settings.functions.transformDate(date)
				});
			});
		}
		return datesArray;
	},[event]);

	const updateAlgoliaHandler = async () => {
		const algoliaImageObject = [];

		datesToSelect.reverse().forEach(date => {
			const eventDayImages =  eventData[date.value].images; 
			const eventDayImagesWithDate = eventDayImages.map(eventDayImage => ({
				...eventDayImage,
				filter: 
					[
						...get(eventDayImage, 'filter', []),
						date.value
					]
			}));
			algoliaImageObject.push(eventDayImagesWithDate);
		}); 
	
		const filterHandler = (filter) => {
			const filterCopy = [...filter];

			if (!filterCopy.includes(event?.eventLocation?.name) ) {
				filterCopy.push(event?.eventLocation?.name);
			}
			if (!filterCopy.includes(event?.calendar?.title) ) {
				filterCopy.push(event?.calendar?.title);
			}
			if (!filterCopy.includes(event?.calendar?.season?.title) ) {
				filterCopy.push(event?.calendar?.season?.title);
			}
			if (!filterCopy.includes(event?.title) ) {
				filterCopy.push(event?.title);
			}
			if (!filterCopy.includes(event?.title) ) {
				filterCopy.push(event?.title);
			}

			return filterCopy;
		};


		const algoliaImageObjectWithObjectID =  algoliaImageObject.flat().map(imageObject => ({
			...imageObject, 
			objectID: imageObject.image.uuid,
			filter: filterHandler(imageObject.filter || [])
		}));

		await algoliaIndex.partialUpdateObjects(algoliaImageObjectWithObjectID, {
			// All the following parameters are optional
			createIfNotExists: true
			// autoGenerateObjectIDIfNotExist:true
			// + any requestOptions
		  })
		  .then(({ objectIDs }) => {
				console.log(objectIDs);
		  });
	};

	const updateHandler = async () => {
		await mutateEvent({ variables: {
			id: event.id,
			event_json: eventData
		}});

		await publishEvent({ variables: {
			id: event.id
		}});

		await updateAlgoliaHandler();

		getEvent();
	};

	useEffect(() => {
		if (event?.date?.length > 0 && !selectedDay) {
			setSelectedDay({
				value: event.date[0],
				label: settings.functions.transformDate(event.date[0])
			});
		}
	}, [event, selectedDay]);

	useEffect(() => {
		getEvent();
	}, [getEvent]);

	return (
		<div>
			<FlexBox justify='space-between' hasFullWidth>
				<h2>{title}</h2>
				<FlexBox direction='row' align='center' gap={settings.sizes.small}>
					<Button
						size='medium'
						text='back'
						color={settings.colors.secondary}
						hoverColor={settings.colors.secondaryShaded}
						onClick={() => navigate('/app/events')}
					/>
					<FullButton
						size='medium'
						text='Save'
						disabled={!dataHasChangend}
						onClick={() => updateHandler()}
					/>
					{/* <FullButton
						size='medium'
						text='Save Search Engine'
						color='secondary'
						// disabled={!dataHasChangend}
						onClick={() => updateAlgoliaHandler()} 
					 /> */}
				</FlexBox>
			</FlexBox>
			<Divider gap='small' />
			<FlexBox direction='row' align='center' gap={settings.sizes.medium}>
				<h4>
					Select Event Day: 
				</h4>
				<ReactSelect options={datesToSelect} value={selectedDay} onChange={inputValue => setSelectedDay(inputValue)}/>
			</FlexBox>
			<Divider gap='small'  />
			
			<Uploader
				event={event}
				eventData={eventData}
				setEventData={setEventData}
				setDataHasChanged={setDataHasChanged}
				selectedDay={selectedDay}
			/>
			
			<Divider />
			<div css={{height: '100%', maxHeight: '600px', overflowY: 'scroll'}}>
				{event && 
					<DisplayImages
						images={get(eventData, `${selectedDay?.value}.images`, [])}
						day={selectedDay?.value}
						eventData={eventData}
						setEventData={setEventData}
						setDataHasChanged={setDataHasChanged}
						eventLocation={event.eventLocation.name}
					/>
				}
			</div>
		</div>
	);
};

EventPage.propTypes = {
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
};

export default EventPage;	