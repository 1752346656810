import React from 'react';

import FlexBox from '@components/layout/FlexBox';
import Divider from '@components/surfaces/Divider';
import Expandable from '@components/surfaces/Expandable';
import Topline from '@components/surfaces/Topline';
import useGetSeasons from '@hooks/useGetSeasons';
import settings from '@settings';

import CalendarElement from './components/CalendarElement';

const Overview = () => {
	const  {seasons} = useGetSeasons();

	const content = events => 
		<>
			{events.map(event => (
				<React.Fragment key={event.remoteId}>
					<CalendarElement  event={event} />
					<Divider gap='small' />
				</React.Fragment>
			))}
		</>;
    
	return (
		<div>
			<Topline text='Backend' />
			<h1>Overview</h1>
			{seasons.map(season => (
				<FlexBox
					key={season.remoteId}
					direction='column'
					align='stretch'
					gap={settings.sizes.medium}
					styles={{marginBottom: settings.sizes.extraLarge}}
				>

					{season.calendars.sort((a,b) => (a.order - b.order)).map((calendarElement, index) => 
						<Expandable 
							key={calendarElement.remoteId}
							title={calendarElement.title}
							mainContent={content(calendarElement.events)}
							isExpanded={index === 0}
						/>
					)}

				</FlexBox>
			))}
		</div>
	);
};

// Overview.propTypes = {
// 	events: PropTypes.array
// };

// Overview.defaultProps = {
// 	events: []
// };

export default Overview;