import { gql, useMutation } from '@apollo/client';

const EVENT_DATA_MUTATION = gql`
    mutation eventDataMutation ($event_json: Json, $id: ID!) {
        updateEvent(data: {event_json: $event_json}, where: {id: $id }) {
          id
          event_json
        }
      }
`;

const EVENT_DATA_PUBLISH = gql`
    mutation PublishEvent ( $id: ID!) {
        publishEvent(where: {id: $id}) {
            id
        }
    }
`;

const useMutateEvents = () => {
	const [mutateEvent, { data, loading }] = useMutation(EVENT_DATA_MUTATION);
	const [publishEvent] = useMutation(EVENT_DATA_PUBLISH);
	return ({
		mutateEvent,
		publishEvent,
		loading,
		data
	});
};

export default useMutateEvents;