import React from 'react';

import Divider from '@components/surfaces/Divider';
import PropTypes from 'prop-types';

const Settings = ({title}) => (
	<div>
		<h1>{title}</h1>
		<Divider />
		<p>noch keine Settings </p>
    
	</div>
);

Settings.propTypes = {
	title: PropTypes.string.isRequired
};

export default Settings;