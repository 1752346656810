/* eslint-disable jsx-a11y/label-has-associated-control */
import FullButton from '@components/buttons/FullButton';
import FlexBox from '@components/layout/FlexBox';
import useGetNations from '@hooks/useGetNations';
import settings from '@settings';
import { Field, Form, Formik } from 'formik';
import { get , set , isEqual } from 'lodash';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';
import Button from '@components/buttons/Button';
import useGetFilters from '@hooks/useGetFilters';
import { imageCardContainer, imageCardFieldContainer, labelStyle } from '../styles';

// eslint-disable-next-line react/jsx-props-no-spreading
const MyInput = ({ field, form, ...props }) => <input css={{height: '38px'}} {...field} {...props} />;

const ImageCard = ({eventData, setEventData, setDataHasChanged, uuid, filter, athletes, title, info, day, start, index}) => {
	const {nations} = useGetNations();
	const filters = useGetFilters();

	// Externalize this not to rtender in every image Card
	const allAthletes = useMemo(() => {
		const athletesArray = [];
		const athleteOptions = [];
		nations.forEach(nation => {
			athletesArray.push(nation.athletes);
			nation.athletes.forEach(athlete => {

				athleteOptions.push({
					value: athlete.remoteId,
					label: athlete.name
				});
			});
		});
		return ({
			athletesArray,
			athleteOptions
		});
	}, [nations]);

	const allFilters = useMemo(() => {
		const filterObject = {};
		filters.forEach(ft => {
			const currentImage = get(eventData, `${day}.images[${index}]`);

			if (currentImage[ft.remoteId]) {
				filterObject[ft.remoteId] = currentImage[ft.remoteId];
			}
		});

		return filterObject;
	}, [eventData, filters, day, index]);

	const changeImages = imageData => {
		const newEventData = {...eventData};
		const currentImage = {
			...get(newEventData, `${day}.images[${index}]`),
			...imageData,
			order: index
		};

		set(newEventData, `${day}.images[${index}]`, currentImage );

		setEventData(newEventData);
		setDataHasChanged(true);
	};

	const deleteImage = () => {
		const newEventData = {...eventData};
		const currentImages = [
			...get(newEventData, `${day}.images`)
		];

		currentImages.splice(index, 1);
		set(newEventData, `${day}.images`, currentImages );
		setEventData(newEventData);
		setDataHasChanged(true);
	};

	return (
		<FlexBox
			direction='row'
			align='center'
			justify='space-between'
			gap={settings.sizes.large}
			styles={imageCardContainer}
		>
			<img
				key={uuid}
				src={`https://ucarecdn.com/${uuid}/-/preview/-/scale_crop/120x80/`}
				width="120"
				height='80'
				alt={title || 'Preview'}
			/>
			<Formik
				initialValues={{
					title,
					info,
					athletes: athletes || [],
					start,
					filter: filter || [],
					...allFilters
				}}
                
				onSubmit={async (values) => changeImages(values)}
			>
				{({ setFieldValue, values, submitForm, initialValues }) => ( 
					<Form css={{flex: '1 1 100%', flexDirection: 'row', display: 'flex', alingItems: 'center', justifyContent: 'space-between', gap: settings.sizes.large}}>
						<div css={imageCardFieldContainer}>
							<label css={labelStyle} htmlFor="title">Title</label>
							<Field
								id="title"
								name="title"
								placeholder="Event Name"
								type="info"
								component={MyInput}
							/>
						</div>
						<div css={imageCardFieldContainer}>
                        
							<label css={labelStyle} htmlFor="title">info</label>
							<Field
								id="info"   
								name="info"
								placeholder="Short Info about the event"
								type="info"
								component={MyInput}
							/>
						</div>
						
						<div css={[imageCardFieldContainer, {width: '300px'}]}>
							<label css={labelStyle} htmlFor="athletes">Athletes</label>
							<ReactSelect
								options={allAthletes.athleteOptions}
								onChange={selectValues => setFieldValue('athletes', selectValues)}
								value={values.athletes}
								styles={{control:  (baseStyle) => ({...baseStyle, width: '300px'})}}
								isMulti
								id='athletes'
							/>
						</div>
						
						{filters.map(filterSelect => (
							<div key={filterSelect.remoteId}  css={[imageCardFieldContainer, {width: '300px'}]}>
								<label css={labelStyle} htmlFor={filterSelect.remoteId}>{filterSelect.label}</label>
								<ReactSelect
									options={filterSelect.categories.map(category => ({value: category, label: category}))}
									onChange={(filterValue, action) => {
										const filterCopy = [...values.filter];
		
										if (action.action === 'select-option') {
											filterCopy.push(action.option.value);
										}

										if (action.action === 'remove-value') {
											const filterOptionIndex = filterCopy.findIndex(category => category === action.removedValue.value );
											filterCopy.splice(filterOptionIndex, 1);
										}

										setFieldValue('filter', filterCopy);
										setFieldValue(`${filterSelect.remoteId}`, filterValue);
									}}
									value={values[filterSelect.remoteId]}
									styles={{control:  (baseStyle) => ({...baseStyle, width: '300px'})}}
									isMulti
								/>
							</div>
						))}

						<div css={[imageCardFieldContainer, {width: '50px'}]}>
							<label css={labelStyle} htmlFor="athletes">Start</label>
							<Field
								id="start"   
								name="start"
								type="checkbox"
								component={MyInput}
							/>
						</div>
						<div css={{display: 'flex', flexDirection: 'column', gap: settings.sizes.small}}>
							<FullButton
								type="submit"
								text='Submit'
								disabled={isEqual(values, initialValues)}
								onClick={() => submitForm()}
								color='secondary'
							/>
							<Button
								text='delete'
								onClick={() => deleteImage()}
								color={settings.colors.dark}
								hoverColor={settings.colors.grey}
							/>
						</div>
						{/* <FullButton type="submit" text='Reset' onClick={() => resetForm()} /> */}
					</Form>
				)}
			</Formik>
		</FlexBox>
	);
};

ImageCard.propTypes = {
	title: PropTypes.string,
	info: PropTypes.string,
	athletes: PropTypes.array,
	filter: PropTypes.array,
	eventData: PropTypes.object.isRequired, 
	setEventData: PropTypes.func.isRequired, 
	setDataHasChanged: PropTypes.func.isRequired, 
	uuid: PropTypes.string,
	day: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	start: PropTypes.bool
};

ImageCard.defaultProps = {
	title: '',
	info: '',
	athletes: [],
	filter: [],
	uuid: '',
	start: false
};

MyInput.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

export default ImageCard;