import React from 'react';

import styled from '@emotion/styled';
import settings from '@settings';
import PropTypes from 'prop-types';

export const Button = ({ text, onClick, color, hoverColor, size, disabled, type }) => {
	let padding = '';
	let fontSize = '';
	const fontColor = color;
	const fontHover = hoverColor;

	if (size === 'tiny') {
		padding = settings.padding.tiny;
		fontSize = settings.fontSize.tiny;
	}
	if (size === 'small') {
		padding = settings.padding.small;
		fontSize = settings.fontSize.semismall;
	}
	if (size === 'medium') {
		padding = settings.padding.medium;
		fontSize = '.9rem';
	}
	if (size === 'large') {
		padding = settings.padding.large;
		fontSize = '1.3rem';
	}

	const StyledButton = styled.button( props =>({
		display: 'inline-block',
		fontFamily: 'Bebas Neue',
		fontWeight: 500,
		textTransform: 'uppercase',
		width: 'auto',
		padding,
		letterSpacing: '0.06em',
		fontSize,
		lineHeight: '1',
		height: 'fit-content',
		border: `1px solid ${props.disabled ? settings.colors.grey : fontColor}`,
		backgroundColor: 'transparent' ,
		color: props.disabled ? settings.colors.grey : fontColor,
		cursor: props.disabled ? 'default' :'pointer',
		transition: 'color 0.2s ease, border 0.2s ease',
		':hover': {
			color: props.disabled ? settings.colors.grey : fontHover,
			border: `1px solid ${props.disabled ? settings.colors.grey : fontHover}`
		}
	}));

	return (
		<StyledButton disabled={disabled} type={type} onClick={() => onClick()}>
			<nobr>{text}</nobr>
		</StyledButton>
	);
};

Button.propTypes = {
	color: PropTypes.string,
	hoverColor: PropTypes.string,
	text: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['small', 'medium', 'large', 'tiny']),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	type: PropTypes.string
};

Button.defaultProps = {
	color: 'primary',
	hoverColor: 'white',
	size: 'medium',
	onClick: () => {},
	disabled: false,
	type: 'button'

};

export default Button;