import { gql } from '@apollo/client';

const GET_EVENT_QUERY = gql`
    query event($id: ID!) {
        event(where: {id: $id}) {
            id
            date
            event_data
            event_json
            title
            calendar {
                id
                title
                season {
                    title
                    id
                }
            }
            eventLocation {
                id
                name
            }
        }
    }
`;

export default GET_EVENT_QUERY;