import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import settings from '@settings';
import FlexBox from '@components/layout/FlexBox';
import {AnimatePresence, motion} from 'framer-motion';
import { RiArrowRightSFill } from 'react-icons/ri';

const Expandable= ({title, content, mainContent, isExpanded}) => {
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
	  if (isExpanded === true) {
			setExpanded(true);
	  }
	}, [isExpanded]);
	
	return (
		<div>
			<div
				onClick={() => setExpanded(!expanded)}
				type='button'
				aria-hidden='true'
				css={{cursor: 'pointer', backgroundColor: settings.colors.white, border: `1px solid ${settings.colors.darkgrey}`, padding: settings.padding.medium, width: '100%'}}
			>
				<FlexBox
					direction='row'
					align="center"
					justify='space-between'
					hasFullWidth
				>

					<div>
						{title &&
							<h3>
								{title}
							</h3>
						}
						
						{content && content()}
					</div>
					<motion.div
						animate={{rotate: expanded ? 90 : 0}}
					>
						<RiArrowRightSFill />
					</motion.div>
				</FlexBox>
			</div>
			<AnimatePresence>
				{expanded &&
					<motion.div
						initial={{opacity: 0}}
						animate={{opacity: 1}}
						exit={{opacity: 0}}
					>
						{mainContent}
					</motion.div>
				}
			</AnimatePresence>

		</div>
	);};

Expandable.propTypes = {
	title: PropTypes.string,
	content: PropTypes.func,
	mainContent: PropTypes.func,
	isExpanded: PropTypes.bool
};

Expandable.defaultProps = {
	title: '',
	content: () => {},
	mainContent: () => {},
	isExpanded: false
};

export default Expandable;