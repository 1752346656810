import FlexBox from '@components/layout/FlexBox';
import settings from '@settings';
import React from 'react';
import PropTypes from 'prop-types';
import ImageCard from './comonents/ImageCard';

const DisplayImages = ({setDataHasChanged, setEventData, images, day, eventData}) => (
	<FlexBox
		direction='column'
		gap={settings.sizes.medium}
		align='stretch'
		hasFullWidth
	>
		{images.map((file,index) => (
			<ImageCard 
				title={file.title}
				info={file.info}
				athletes={file.athletes}
				key={file.image.uuid}
				uuid={file.image.uuid}
				start={file.start}
				index={index}
				day={day}
				filter={file.filter}
				eventData={eventData}
				setDataHasChanged={setDataHasChanged}
				setEventData={setEventData}
			/>
		))}
        
	</FlexBox>
);

DisplayImages.propTypes = {
	setDataHasChanged: PropTypes.func, 
	setEventData: PropTypes.func.isRequired, 
	images: PropTypes.array,
	day: PropTypes.string, 
	eventData: PropTypes.object.isRequired
};

DisplayImages.defaultProps = {
	setDataHasChanged: () => {}, 
	images: [],
	day: '' 
};

export default DisplayImages;