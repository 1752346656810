import { graphql, useStaticQuery } from 'gatsby';

const useGetNations = () => {
	const nations = useStaticQuery(graphql`
		query getNations {
            allGraphCmsNation(sort: {fields: name, order: ASC}) {
                nodes {
                athletes {
                    id
                    name
                    remoteId
                }
                flag {
                    id
                    url
                    gatsbyImageData(
                        height: 12
                    )
                }
                short
                id
                remoteId
                name
                remoteId
                }
            }
        }
	`);

	return ({
		nations: nations.allGraphCmsNation.nodes.map(nation => nation)
	});
};

export default useGetNations;