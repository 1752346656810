import React, { useEffect } from 'react';

import Container from '@components/Container';
import useGetEvents from '@hooks/useGetEvents';
import useUserStore from '@hooks/useUserStore';
import Layout from '@mainComponents/Layout';
import { Router } from '@reach/router';
import GoTrue from 'gotrue-js';

import PrivateRoute from './components/PrivateRoute';
// import EventPage from './content/EventPage';
import WrapperBackend from './components/WrapperBackend';
import EventPage from './content/EventPage';
import Login from './content/Login';
import Overview from './content/Overview';
import Settings from './content/Settings';


const Backend = () => {
	const events = useGetEvents();
	const {user, setUser} = useUserStore();
	
	const auth = new GoTrue({
		APIUrl: 'https://www.nocogirls.com/.netlify/identity',
		audience: '',
		setCookie: false
	});

	useEffect(() => {
		if (window !== 'undefined') {

			const storedUser = JSON.parse( localStorage.getItem('gotrue.user'));
			if (storedUser) {
				setUser(storedUser);
			}
		}
	}, []);

	return (
		<Layout>
			<WrapperBackend>
				<Container>

					<Router basepath="/app">
						<Login
							auth={auth}
							path='/login'
							default
						/>
						<PrivateRoute
							title='Events'
							id='events'
							user={user}
							component={Overview}
							events={events}
							path="/events"
						/>
						<PrivateRoute
							title='Settings'
							id='settings'
							user={user}
							component={Settings}
							events={events}
							path="/settings"
						/>
						{events && events.map(event => 
							<PrivateRoute
								title={event.title}
								id={event.remoteId}
								user={user}
								events={events}
								component={EventPage}
								event={event}
								path={`/events/${event.remoteId}`}
						
							/>
						)}
					</Router>
				</Container>
			</WrapperBackend>
		</Layout>
	);
};

export default Backend;