/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';

import FlexBox from '@components/layout/FlexBox';
import useUserStore from '@hooks/useUserStore';
import { Field, Form, Formik } from 'formik';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';

const Login = ({auth}) => {
	const {user, setUser} = useUserStore(); 

	const loginHandler =(data) => {
		auth.login(data.email, data.password)
			.then((response) => {
				setUser(response);
			})
			.catch((error) => console.log('It\'s an error', error));
	};
	useEffect(() => {
		if (user?.id) {
			navigate('/app/events');
		}
	}, [user]);
    
	return (
		<FlexBox
			direction='row'
			align='center'
			justify='center'
			styles={{width: '100%', height: '100%'}}
		>
			<Formik
				initialValues={{
					email: '',
					password: ''
				}}
				onSubmit={async (values) => loginHandler(values)}
			>

				<Form css={{flexDirection: 'column', display: 'flex'}}>
					<label htmlFor="email">Email</label>
					<Field
						id="email"
						name="email"
						placeholder="jane@acme.com"
						type="email"
					/>
					<label htmlFor="password">Password</label>
					<Field
						type='password'
						id="password"
						name="password"
						placeholder="123"
						autocomplete
                        
					/>
					<button type="submit">Submit</button>
				</Form>


			</Formik>
			
		</FlexBox>
	);
};

Login.propTypes = {
	auth: PropTypes.object.isRequired
};

export default Login;