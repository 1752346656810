import React from 'react';

import {navigate} from 'gatsby';
import PropTypes from 'prop-types';

const PrivateRoute = ({id, title, user, component: Component, ...rest }) => {
	
	const accessHandler = () => {
		let hasAccess = false;
		
		if (user) {
			hasAccess = true;
		}

		return hasAccess;
	  };
	
	
	if (accessHandler() === true) {
		return (
			<Component
				title={title}
				id={id}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...rest}
			/>
		);
	}
	if (accessHandler() === false) {
		navigate('/app/login');
	}
	return null;
};

PrivateRoute.propTypes = {
	path: PropTypes.string.isRequired,
	component: PropTypes.any.isRequired,
	title: PropTypes.string,
	id: PropTypes.string
};

PrivateRoute.defaultProps = {
	title: '',
	id: ''
};

export default PrivateRoute;
