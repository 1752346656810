import styled from '@emotion/styled';
import settings from '@settings';

const WrapperBackend = styled.div(settings.mq({
	marginLeft: 'auto',
	paddingTop: '40px',
	paddingBottom: settings.sizes.small,
	marginRight: 'auto',
	fontSize: ['13px', '13px', '14px', '14px', '14px'],
	height: '100%',
	minHeight: '600px',
	'h1': {fontSize: '3.6rem'},
	'h2': {fontSize: '3rem'}
}),
props => ({backgroundColor: props.background})

);

export default WrapperBackend;